<template>
    <div id="sidebar-search">

        <div class="sidebarBlock">
            <PinnedMessage/>
        </div>

        <div class="sidebarBlock" v-if="this.$parent.resultsFiltered.length && inSalabamInfoBox && Object.keys(inSalabamInfoBox) !== 0">
          <InfoBox :inSalabamInfoBox="inSalabamInfoBox" />
        </div>

        <div class="sidebarBlock">
            <ResultsHead
                :total="this.$parent.resultsFiltered.length"
                :geoLabel="geo.label"
                :possibleProperties="this.$parent.possibleProperties" 
            />
        </div>

        <div class="sidebarBlock">
            <div class="toggle-map-block-wrapper" v-if="this.$route.name == 'search'">
                <router-link :to="{ 
                    name: 'map-list',
                    params: { slug: (geo.label).replace(/ /g, '-'),
                    title: geo.label,
                    propertyId: 0}
                }">
                <div class="panel-white small">
                    <div class="map-block" v-if="this.$parent.resultsFiltered.length">
                        <p class="map-link-title">Vedi su mappa</p>
                        <div class="map-link-svg-small">
                            <SvgMapSmall />
                        </div>
                    </div>
                </div>
                </router-link>
            </div>
            <div class="toggle-map-block-wrapper" v-if="this.$route.name == 'map-list'">
                <router-link :to="{ 
                    name: 'search',
                    params: {}
                }">
                <div class="panel-white small">
                    <div class="map-block" v-if="this.$parent.resultsFiltered.length">
                        <p class="map-link-title">Vai alla lista</p>
                        <div class="map-link-svg-small">
                            <SvgList />
                        </div>
                    </div>
                </div>
                </router-link>
            </div>
        </div>

        <div class="sidebarBlock">
            <SuperInSlider v-if="inSalabamInfoBox && inSalabamInfoBox.category && inSalabamInfoBox.category.code" :category="inSalabamInfoBox.category" :skip="[parseInt(inSalabamInfoBox.inSalabamId)]" />
        </div>

        <div class="sidebarBlock">
            <FiltersAccordion :resultsFiltered="resultsFiltered" />
        </div>

        <!-- MAGIC FILTER NASCOSTO
        <MagicFilters /> -->
    </div>
</template>

<script>
import Vue from 'vue'
import FiltersAccordion from './filters-accordion'
import ResultsHead from '../results-head'
//import MagicFilters from './magic-filters'
import SvgMapSmall from '../../svg/map-link-small'
import SvgList from '../../svg/list'
import PinnedMessage from '../../blocks/common/pinnedMessage.vue'
import SuperInSlider from '../../blocks/sidebar/super-in-slider.vue'
import InfoBox from './info-box.vue'

export default {
    name: 'sidebar-search',
    components:
    {
        SvgMapSmall,
        SvgList,
        //MagicFilters,
        FiltersAccordion,
        PinnedMessage,
        InfoBox,
        SuperInSlider,
        ResultsHead,
    },
    props:
    {
        //focus: String
        resultsFiltered: Array,
        possibleProperties: Number,
        inSalabamInfoBox: Object,
    },
    computed: {
        geo: {
            get() { return this.$store.state.geo },
            set(value) { this.mixinSendMutation('setGeo',value) }
        }
    },
    data() {
        return {
            //credit: 'welfare',
            config: Vue.prototype.$config
        }
    },
    methods:
    {
        changeCredit: function(e){
            if(e.target.className == 'custom-control-label' | e.target.className == 'custom-control-input')
            {
                let parent = e.target.parentNode
                let input = parent.querySelectorAll('input')[0]
                this.credit = input.value
            }
        }
    }  
}
</script>

<style lang="scss">
    
    
    
    

    #sidebar-search
    {
        .toggle-map-block-wrapper
        {
            *:hover,
            *:hover *
            {
                text-decoration: none;
            }            
            .panel-white
            {
                padding-top:0;
                padding-bottom:0;
                padding-right:0;
            }
        }
        .map-block
        {
            display: flex;
            align-items:center;
            justify-content: space-between;
            .map-link-title
            {
                flex:1;
                text-align: center;
                font-size: 14px;
                line-height: normal;
                @include customize-color("cta");
                font-weight: $font-weight-bold;
                margin-bottom:0;
                text-transform: uppercase;
                text-decoration: none !important;

                @include media-breakpoint-up(xxl)
                {
                    font-size: 17px
                }
            }
        }
    }
</style>