<template>
    <div class="search-form-filters">
        <div id="modal-filters" size="xl" class="desktop-only">

            <!-- ordinamento -->
            <div class="sidebarBlock">
                <p class="common-title">Ordina per</p>
                <div class="panel-white small filter-order">
                    <p class="amenity-filter-title" v-b-toggle="'accordion-order'" variant="info">
                        <span class="filter-icon"><SvgOrder /></span>
                        <span class="filter-title">{{sortResults.label}}</span>
                        <SvgChevronDownToggler class="filter-chevron" />
                    </p>
                    <b-collapse :id="'accordion-order'" :accordion="'filters-accordion-order'" role="tabpanel">
                        <b-form-group>
                            <b-form-radio @change="triggerSortResults('defaultSorting','ASC','Default')" v-model="order" name="order-defaultSorting" value="defaultSorting"><p>Default</p></b-form-radio>
                            <b-form-radio @change="triggerSortResults('proximityDistance','ASC','Distanza')" v-model="order" name="order-" value="proximityDistance"><p>Distanza</p></b-form-radio>
                            <b-form-radio @change="triggerSortResults('live_price','ASC','Prezzo più basso')" v-model="order" name="order-live_priceAsc" value="live_priceAsc"><p>Prezzo più basso</p></b-form-radio>
                            <b-form-radio @change="triggerSortResults('live_price','DESC','Prezzo più alto')" v-model="order" name="order-live_priceDesc" value="live_priceDesc"><p>Prezzo più alto</p></b-form-radio>
                            <b-form-radio @change="triggerSortResults('salabamScore','DESC','Rating')" v-model="order" name="order-salabamScoreDesc" value="salabamScoreDesc"><p>Rating</p></b-form-radio>
                        </b-form-group>
                    </b-collapse>
                </div>
            </div>

            <!-- credito welfare -->
            <div class="sidebarBlock">
                <p class="common-title">Filtra i risultati</p>

                <!--flagWelfare-->
                <div v-if="this.$config.integration.availability > 0" class="filter-availability"
                    v-b-popover.hover.top="'Con l\'interruttore accesso vedrai solo strutture con costo uguale o inferiore al tuo credito'+(mixinIsWelfare() ? ' welfare':'')+'. Con l\'interruttore spento vedi tutte le strutture disponibili.'"
                >
                    <div class="filter-availability-info">
                        <SvgInfoCircle />
                        <p>
                            Mostra in base a
                            <span v-if="mixinIsWelfare()">Credito Welfare</span><span v-else>Credito</span>
                        </p>
                    </div>
                    <CustomSwitch
                        :status="welfareAvailability"
                        :switchUniqueKey="'2'"
                        v-on:switch-change="checked => this.switchedAvailabilityFilter(checked)"
                    />
                </div>

                <!--<div class="filter-refundable">
                  <div class="filter-refundable-info" v-b-popover.hover.top="'Ti mostreremo solamente soluzioni rimborsabili'">
                    <SvgInfoCircle />
                    <p>
                      Solo camere rimborsabili
                    </p>
                  </div>
                  <CustomSwitch
                    :status="selectedAmenities.includes('refundable')"
                    :switchUniqueKey="'1'"
                    v-on:switch-change="checked => switchChanged(checked)"
                  />
                </div>-->

            </div>

            <!-- filtri -->
            <div class="sidebarBlock">
                <b-form-group>
                    <div 
                        v-for="(amenity,index) in amenities"
                        v-bind:key="amenity.label"
                    >
                        <!-- tutti i filtri tranne refundable -->
                        <div v-if="amenity.label !== 'Refundable'" class="panel-white small amenity-tab">
                            <p class="amenity-filter-title" v-b-toggle="'accordion-amenity-'+index" variant="info">
                                <span class="filter-icon"><SvgToggleSearch /></span>
                                <span class="filter-title">{{amenity.label}}</span>
                                <SvgChevronDownToggler class="filter-chevron" />
                            </p>
                            <b-collapse
                                :visible="!showAll[(index + 'Amenities')]"
                                :id="'accordion-amenity-'+index"
                                :accordion="'accordion-amenity-'+index"
                                role="tabpanel"
                                >
                                <b-form-group>
                                    <b-form-checkbox
                                        class="showAll"
                                        v-model="showAll[(index + 'Amenities')]"
                                        @change="resetFilter(index)"
                                    >
                                        <p>Vedi tutto</p>
                                        <!--<span class="filter-number">{{ (amenity.filters).reduce((a, b) => a + (b['number'] || 0), 0) }}</span>-->
                                    </b-form-checkbox>

                                    <b-form-checkbox-group :id="'amenities-'+amenity.label" v-model="selectedAmenities" :name="amenity.label" stacked>
                                        <b-form-checkbox 
                                            v-for="f in amenity.filters" 
                                            v-bind:key="f.label" 
                                            :value="f.value"
                                            @change="triggerFilterChange()"
                                        >
                                            <p>{{f.text}}</p>
                                            <span v-if="f.number && resultsFiltered.length" class="filter-number">{{f.number}}</span>
                                        </b-form-checkbox>
                                    </b-form-checkbox-group>
                                </b-form-group>
                                <p class="amenity-filter-info">
                                    <span class="svg"><SvgInfo /></span>

                                    <span v-if="amenity.label == 'Servizi inclusi'" class="text">
                                        Questi servizi saranno inclusi nel prezzo
                                    </span>
                                    <span v-else class="text">
                                        Alcuni di questi servizi, potrebbero richiedere supplementi da pagare in loco
                                    </span>
                                </p>
                            </b-collapse>
                        </div>
                    </div>

                    <div class="panel-white small amenity-tab">
                        <p class="amenity-filter-title" v-b-toggle="'accordion-category'" variant="info">
                            <span class="filter-icon"><SvgToggleSearch /></span>
                            <span class="filter-title">Tipologia struttura</span>
                            <SvgChevronDownToggler class="filter-chevron" />
                        </p>
                        <b-collapse
                            :visible="!showAll.categories"
                            :id="'accordion-category'"
                            :accordion="'filters-accordion-category'"
                            role="tabpanel"
                        >
                            <b-form-group>
                                <b-form-checkbox
                                    class="showAll"
                                    v-model="showAll.categories"
                                    @change="resetFilter('category')"
                                >
                                    <p>Vedi tutto</p>
                                    <!--<span class="filter-number">{{ (amenity.filters).reduce((a, b) => a + (b['number'] || 0), 0) }}</span>-->
                                </b-form-checkbox>

                                <b-form-checkbox-group v-model="selectedCategories" name="'category'" stacked>
                                    <b-form-checkbox 
                                        v-for="(category,index) in categories" 
                                        v-bind:key="index" 
                                        :value="category.value"
                                        @change="resetResultsAndTrigger()"
                                    >
                                        <p>{{category.text}}</p>
                                        <span v-if="category.number && resultsFiltered.length" class="filter-number">{{category.number}}</span>
                                    </b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                            <!--
                            <p class="amenity-filter-info">
                                <span class="svg"><SvgInfo /></span>
                                <span class="text">
                                    Alcuni di questi servizi, potrebbero richiedere supplementi da pagare in loco.
                                </span>
                            </p>
                            -->
                        </b-collapse>
                    </div>

                    <div class="panel-white small amenity-tab">
                        <p class="amenity-filter-title" v-b-toggle="'accordion-rates'" variant="info">
                            <span class="filter-icon"><SvgToggleSearch /></span>
                            <span class="filter-title">Stelle</span>
                            <SvgChevronDownToggler class="filter-chevron" />
                        </p>
                        <b-collapse
                            :visible="!showAll.stars"
                            :id="'accordion-rates'"
                            :accordion="'filters-accordion-amenity'"
                            role="tabpanel"
                        >
                            <b-form-group>
                                <b-form-checkbox
                                    class="showAll"
                                    v-model="showAll.stars"
                                    @change="resetFilter('star')"
                                >
                                    <p>Vedi tutto</p>
                                    <!--<span class="filter-number">{{ (amenity.filters).reduce((a, b) => a + (b['number'] || 0), 0) }}</span>-->
                                </b-form-checkbox>

                                <b-form-checkbox-group v-model="selectedRates" name="'rates'" stacked>
                                    <b-form-checkbox 
                                        v-for="rate in [3,4,5]" 
                                        v-bind:key="rate" 
                                        :value="rate"
                                        @change="resetResultsAndTrigger()"
                                    >
                                        <p>{{rate}} Stelle</p>
                                        <!--<span v-if="category.number && resultsFiltered.length" class="filter-number">{{category.number}}</span>-->
                                    </b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </b-collapse>
                    </div>                


                </b-form-group>
            </div>

            <!--<b-button variant="secondary" ref="filterButtonSubmit" @click="filterChange()">APPLICA FILTRI</b-button>-->
        </div>

    </div>
</template>

<script>
    import Vue from 'vue'
    import SvgOrder from '../../svg/order'
    import SvgToggleSearch from '../../svg/toggle-search'
    import SvgChevronDownToggler from '@/components/svg/chevron-down-toggler'
    import SvgInfo from '../../svg/info'
    import SvgInfoCircle from '../../svg/info-circle'
    import CustomSwitch from '../../atoms/custom-switch'
    export default {
        components:
        {
            SvgToggleSearch, SvgChevronDownToggler, SvgInfo, SvgInfoCircle, SvgOrder,
            CustomSwitch
        },
        props:{
            resultsFiltered: Array
        },
        watch:{
            resultsFiltered: function() {
                this.setupFilters()
            }
        },
        computed: {
            availability: {
              get() { return this.$store.state.integration.availability },
              set(value) { this.mixinSendMutation('setAvailabilty',value) }
            },
            selectedAmenities: {
                get() {
                    let s = this.$store.state.amenities
                    return s
                },
                set(value) {
                    this.mixinSendMutation('setAmenities',value)
                }
            },
            selectedCategories: {
                get() {
                    let s = this.$store.state.categories
                    return s
                },
                set(value) {
                    this.mixinSendMutation('setCategories',value)
                }
            },
            selectedRates: {
                get() {
                    return this.$store.state.rates
                },
                set(value) {
                    this.mixinSendMutation('setRates',value)
                }
            },
            welfareAvailability: {
                get() {
                    return this.$store.state.welfareAvailability
                },
                set(value) {
                    this.mixinSendMutation('setWelfareAvailability',value)
                }
            },
            sortResults:
            {
                get() {
                    return this.$store.state.sortResults
                },
                set(value) {
                    this.mixinSendMutation('setSortResults',value)
                }
            },
        },
        data(){
            return {
                config: Vue.prototype.$config,
                defaultAmenities: {
                    property:{
                        label: "Caratteristiche struttura ", filters: []
                    },
                    rate:{
                        label: "Servizi inclusi", filters: [] // c'è un if che valuta il testo "Servizi inclusi"
                    },
                    refundable:{
                        label: "Refundable", filters: []
                    }
                },                
                amenities: {},
                categories: {},
                order: this.$store.state.sortResults.by,
                showAll:
                {
                    propertyAmenities: false,
                    rateAmenities: false,
                    refundableAmenities: false,
                    categories: false,
                    stars: false,
                }
            }
        },
        mounted()
        {
            this.setupFilters()
        },    
        methods: {
            setupFilters()
            {
                let amenities = JSON.parse(JSON.stringify(this.defaultAmenities))
                for (let key in this.config.amenities) {
                    let level = this.config.amenities[key].level

                    let number = this.resultsFiltered.filter(function (el) {
                            return key in el.amenities
                        }).length

                    if(number !== 0){
                        amenities[level].filters.push({
                            text: this.config.amenities[key].label,
                            value: key,
                            number: number 
                        })
                    }

                    amenities[level].level = level
                    amenities[level].filters.sort(function(a, b) {
                        return b.number - a.number;
                    })
                }

                this.amenities = amenities

                // dentro amenities le ho divise (rate, refundable, property) mentre dentro selectedAmenities sono un unico array
                // quindi devo loopare amenities.type.filters e controllare quante di quelle sono effettivamente selected veridicandone la presenza dentro selectedAmenities
                Object.values(amenities).forEach((type,index) => {
                    let t = Object.keys(amenities)[index],
                        show = true
                    
                    type.filters.forEach(filter => {
                        if (this.selectedAmenities.includes(filter.value))
                        {
                            show = false
                            return
                        }
                    })
                    this.showAll[(t+'Amenities')] = show
                })

                let categories = []

                for (let key in this.config.salabamCategories) {
                    let text = this.config.salabamCategories[key],
                        value = key,
                        number = this.resultsFiltered.filter(function (el) {
                            return el.salabamCategory == key
                        }).length
                    
                    //if(number !== 0){
                        categories.push({
                            text: text,
                            value: value,
                            number: number
                        })
                    //}
                }
                /*
                categories.sort(function(a, b) {
                    return b.number - a.number;
                })
                */

                this.showAll.categories = (this.selectedCategories.length == 0)
                this.categories = categories
                this.showAll.stars = (this.selectedRates.length == 0)
            },
            triggerSortResults(by,order,label)
            {
                this.mixinSendMutation('setSortResults',{
                    by: by,
                    order: order,
                    label: label
                })
            },
            resetResultsAndTrigger()
            {
                let _this = this
                setTimeout(function(){
                    _this.$parent.$parent.$emit('reset-results-and-trigger') //evento emesso direttamente verso search.vue ($parent.$parent)
                },50)
            },
            triggerFilterChange()
            {
                let _this = this
                setTimeout(function(){
                    _this.$parent.$parent.$emit('filter-changed') //evento emesso direttamente verso sarch.vue ($parent.$parent)
                },50)
            },
            switchedAvailabilityFilter(checked)
            {
                this.mixinSendMutation('setWelfareAvailability',checked)
                this.triggerFilterChange()
            },
            switchChanged(value)
            {
                // TODO trick - ci sono 2 refundable all'inizio nell'array
                // e fa so che vengano mostrate sempre e cmq solo refundable
                //this.selectedAmenities.pop('refundable')
                //this.selectedAmenities.pop('refundable')
                if(value)
                {
                    this.selectedAmenities.push('refundable')
                }
                else
                {
                    this.selectedAmenities.pop('refundable')
                }

                this.$parent.$parent.$emit('filter-changed',{
                    selectedAmenities: this.selectedAmenities,
                    selectedCategories: this.selectedCategories
                })
            },
            resetFilter(type)
            {
                switch (type) {
                    case 'property':
                        (this.amenities[type].filters).forEach(filter => {
                            let index = (this.selectedAmenities).indexOf(filter.value)
                            this.selectedAmenities.splice(index, 1)
                        })
                        break;
                
                    case 'rate':
                        (this.amenities[type].filters).forEach(filter => {
                            let index = (this.selectedAmenities).indexOf(filter.value)
                            this.selectedAmenities.splice(index, 1)
                        })
                        break;
                
                    case 'refundable':
                        (this.amenities[type].filters).forEach(filter => {
                            let index = (this.selectedAmenities).indexOf(filter.value)
                            this.selectedAmenities.splice(index, 1)
                        })
                        break;
                
                    case 'category':
                        this.selectedCategories = []
                        break;
                
                    case 'star':
                        this.selectedRates = []
                        break;
                
                    default:
                        break;
                }
                
                this.$parent.$parent.$emit('filter-changed',{
                    selectedAmenities: this.selectedAmenities,
                    selectedCategories: this.selectedCategories,
                    selectedRates: this.selectedRates,
                })
            }
        }
    }
</script>


<style lang="scss">
    
    
    
    

    #modal-filters
    {
        $icon-pad: 12px;
        $icon-size: 25px;
        label
        {
            color:$black;
            font-weight:normal;
            &.custom-control-label p
            {
                font-size: 16px;
            }
            @include media-breakpoint-up(xl)
            {
                p{font-size: 18px;}
            }
        }
        .form-group
        {
            margin:$grid-gutter-width/2 0;
        }
        .amenity-tab
        {
            //see .panel-white .small
            margin-bottom:20px;
        }
        .panel-white
        {
            position:relative;
        }
        .amenity-filter-title
        {
            color:$black;
            font-size:18px;
            margin-bottom:0;
            cursor:pointer;
            display:flex;
            //position:relative;
            .filter-title
            {
                padding-left:$icon-pad;
                font-weight:400;
                font-size:18px;
                color:$black;
            }

            .filter-chevron
            {
                width: 40px;
            }  
        }
        .filter-icon
        {
            width:$icon-size;
            height:$icon-size;
            svg
            {
                width:$icon-size;
                height:$icon-size;
            }
        }
        .filter-chevron
        {
            position:absolute;
            right:18px;
            top: 5px;

             @include media-breakpoint-up(xl)
            {
                top: 12px;
            }

            .arrow
            {
                transform:rotate(180deg);
                transform-origin:center center;
                transition:$transition-base;
            }
        }
        .collapsed .filter-chevron .arrow
        {
            transform:rotate(0);
        }
        .amenity-filter-info
        {
            margin-bottom:0;
            .svg
            {
                display:inline-block;
                width:$icon-size;
                vertical-align: middle;
                text-align:center;
            }
            .text
            {
                display:inline-block;
                vertical-align: middle;
                width:calc(100% - #{$icon-size});
                padding-left:$icon-pad;
                line-height: 120%;
                font-size: 15px;
                color:$smooth-black;
                font-weight:400;
            }
        }
        .filter-number
        {
            color:$dark-gray;
            font-size: 18px;
            width:65px;
            //border:1px solid red;
            text-align: center;
        }
        
        .filter-availability
        {
            display:flex;
            font-weight:400;
            align-items: center;
            justify-content: space-between;

            .filter-availability-info
            {
                display: flex;
                align-items:center;
                
                svg,p
                {
                    display:inline-block;
                    //margin-right:1rem;
                    margin-bottom:0;
                    vertical-align: middle;
                    font-size:16px;
                }
                p
                {
                    padding:0 0.75rem;
                }
            }

            .custom-switch {
                height: 35px;
            }
        }
        .filter-order
        {
            margin-top:$grid-gutter-width/2;
            margin-bottom:$grid-gutter-width;
        }
        .filter-refundable
        {
            display:flex;
            font-weight:400;
            align-items: center;
            justify-content: space-between;
            
            .filter-refundable-info
            {
                display: flex;
                align-items:center;

                svg,p
                {
                    display:inline-block;
                    //margin-right:1rem;
                    margin-bottom:0;
                    vertical-align: middle;
                    font-size:16px;
                }
                p
                {
                    padding:0 0.75rem;
                }
            }
        }
        .custom-checkbox,
        .custom-radio
        {
            .custom-control-label
            {
                height:$icon-size;
                display:flex;
                align-items: center;
                justify-content: space-between;
                padding-left:$icon-pad;
                p
                {
                    margin-bottom:0;
                    //font-size:18px;
                    color:$black;
                    flex:1;
                }
                p:first-letter
                {
                    text-transform: uppercase;
                }
            }
            &:not(:last-child) .custom-control-label
            {
                margin-bottom:1rem;
            }
            .custom-control-label:after,
            .custom-control-label:before
            {
                height:$icon-size;
                width:$icon-size;
                border-radius: 50%;
                top:50%;
                transform:translateY(-50%);
                box-shadow: none;
                border-color:$gray-500;
            }

            .custom-control-label:before
            {
                border-width:2px;
            }
            .custom-control-input:focus ~ .custom-control-label::after
            {
                border-color:$gray-500;
            }
            .custom-control-input:checked ~ .custom-control-label::after
            {
                background-image:none;
                border:2px solid map-get($theme-colors, "secondary");
                @include customize-border-color("secondary");
            }
            .custom-control-input:checked ~ .custom-control-label::before
            {
                width:13px;
                height:13px;
                top:6px;
                left:-18px;
                transform:none;
                @include customize-background-color("secondary");
                border:0;
            }
        }

        .showAll
        {
            .custom-control-input:checked ~ .custom-control-label::after
            {
                @include customize-border-color("success");
            }
            .custom-control-input:checked ~ .custom-control-label::before
            {
                @include customize-background-color("success");
            }
        }


        @include media-breakpoint-only(lg)
        {
            label.custom-control-label p
            {
                font-size: 14px;
                max-width: 70%;
            }
            .amenity-filter-title
            {
                align-items:center;
                .filter-title
                {
                    font-size:14px;
                    line-height: 100%;
                    padding-right:12px;
                }
                .filter-chevron
                {
                    width: 32px;
                }                
            }
            .amenity-tab, .filter-order
            {
                padding:14px 16px;
            }
            .filter-icon,
            .filter-icon svg
            {
                width:16px;
            }
        }
    }

</style>