<template>
  <div class="inSalabamInfoBox">

    <div class="back" @click="goToHomeInSalabam()"><BtnBack :text="'Vai alla Home dell\'IN|'+config.site.brand" /></div>
    <div class="content">
      <div v-b-toggle.collapse-info class="info-chevron">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g class="info-chevron-g">
            <path opacity="0.3" d="M20.8999 41.8C32.4427 41.8 41.7999 32.4428 41.7999 20.9C41.7999 9.35725 32.4427 0 20.8999 0C9.35715 0 0 9.35725 0 20.9C0 32.4428 9.35715 41.8 20.8999 41.8Z" fill="#C88934"/>
            </g>
            <g class="info-chevron-g" filter="url(#filter0_d)">
            <path d="M20.8999 36.8C29.6812 36.8 36.7999 29.6813 36.7999 20.9C36.7999 12.1187 29.6812 5 20.8999 5C12.1186 5 5 12.1187 5 20.9C5 29.6813 12.1186 36.8 20.8999 36.8Z" fill="#FBAE48"/>
            </g>
            <path d="M14 18.2001C14 17.8001 14.0999 17.5001 14.3999 17.2001C14.8999 16.7001 15.7999 16.7001 16.3999 17.2001L20.8999 21.7001L25.3999 17.2001C25.8999 16.7001 26.7999 16.7001 27.3999 17.2001C27.9999 17.7001 27.8999 18.6001 27.3999 19.2001L21.8999 24.7001C21.3999 25.2001 20.4999 25.2001 19.8999 24.7001L14.3999 19.2001C14.1999 18.9001 14 18.5001 14 18.2001Z" fill="#39414E"/>
            <defs>
            <filter id="filter0_d" x="3" y="3" width="37.7999" height="37.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
            <feOffset dx="1" dy="1"/>
            <feGaussianBlur stdDeviation="1.5"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
        </svg>
      </div>

      <p class="label">
        In|{{ config.site.brand }}
        <span v-if="inSalabamInfoBox.category.label != ''"> - {{inSalabamInfoBox.category.label}}</span>
      </p>
      <div class="title" v-html="inSalabamInfoBox.title"></div>

      <div class="abstract" v-b-toggle.collapse-info v-html="inSalabamInfoBox.abstract"></div>

      <b-collapse id="collapse-info">
        <div class="imgSrc" :style="'background-image:url('+inSalabamInfoBox.imgSrc+')'"></div>
        <div class="texts">
            <div class="text" v-for="(text, index) in inSalabamInfoBox.texts" v-bind:key="index">
                <div class="texts--title">{{text.title}}</div>
                <div class="texts--text">{{text.text}}</div>
            </div>
        </div>

        <div class="disclaimer" v-if="inSalabamInfoBox.disclaimer">
          <div class="disclaimer--icon"><SvgDisclaimer /></div>
          <div class="disclaimer--text" v-if="inSalabamInfoBox.disclaimer" v-html="inSalabamInfoBox.disclaimer"></div>
          <div class="disclaimer--text" v-else-if="inSalabamInfoBox.sysInSalabamDefaultDisclaimer" v-html="inSalabamInfoBox.sysInSalabamDefaultDisclaimer"></div>
        </div>

        <div class="credits" v-html="inSalabamInfoBox.credits"></div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import BtnBack from '../../atoms/btn-back'
import SvgDisclaimer from '../../svg/disclaimer'
export default {
    name: 'inSalabamInfoBox',
    components:
    {
      BtnBack,
      SvgDisclaimer,
    },
    props:
    {
        inSalabamInfoBox:{
            type: Object,
            default: () => {}
        }
    },
    data()
    {
        return {
            config: this.appConfig(),
            site: this.$config.site,
        }
    },
    methods:
    {
        goToHomeInSalabam()
        {

            this.mixinGoTo('in-' + this.site.brand.toLowerCase(), {
              inSalabamId: this.inSalabamInfoBox.inSalabamId,
              inSalabamTitle: (this.inSalabamInfoBox.title).toLowerCase().split(' ').join('-').replace(/[^a-zA-Z- ]/g, '').replace(/--+/g, '-'),
              setGeo: this.inSalabamInfoBox.setGeo,
            })

        },
    }
}
</script>

<style lang="scss">

    .inSalabamInfoBox
    {
        $infoSideColor: #39414E;
        /*@include customize-background-color("secondary");*/

        color:$infoSideColor;
        padding:$grid-gutter-width/2 $grid-gutter-width/2 $grid-gutter-width/4 $grid-gutter-width/2;
        border-radius: $border-radius-lg;
        overflow:hidden;
        position:relative;
        //min-height: none;
        //padding:$grid-gutter-width;
        .top
        {
            min-height: 150px;
            background-size:cover;
            background-position:center center;
            @include media-breakpoint-up(lg)
            {
                min-height: 200px;
            }
        }
        .down
        {
            @include customize-color("dark");
            //min-height: 100px;
            padding:$grid-gutter-width/3 $grid-gutter-width/2;
            display: flex;
            justify-content: flex-start;
            align-items:center;
            font-size:20px;
            font-weight:500;
        }
        .link
        {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height: 100%;
            cursor: pointer;
            z-index: 10;
        }
        .content
        {
            position:relative;
            margin-top:$grid-gutter-width/3;
        }
        .btn-back
        {
            @include customize-color("cta");
            justify-content: flex-end;
            font-size:14px;
            svg
            {
                height:auto;
                width:16px;
                transform:translateX(8px);
            }
            path
            {
                @include customize-fill("cta");
            }
        }
        .info-chevron
        {
            position:absolute;
            right:0;
            top:0;
            transition:$transition-base;
            cursor:pointer;
            &.not-collapsed
            {
                transform:rotate(180deg);
            }

            svg .info-chevron-g path {
              @include customize-fill("primary");
            }

        }
        .abstract
        {
            font-size: 14px;
            margin-top:$grid-gutter-width/6;
            position:relative;
            cursor: pointer;
            &:after
            {
                content:"";
                position:absolute;
                width:100%;
                height:50%;
                left:0;
                bottom:0;
                /*background: linear-gradient(180deg, transparent, map-get($theme-colors, "secondary") 100%);*/
                transition: $transition-base;
            }
            &.not-collapsed:after
            {
                opacity: 0;
            }
        }
        .label
        {
            font-size: 16px;
            font-weight: 400;
            margin-bottom:8px;
        }
        .title
        {
            font-size: 22px;
            font-weight: 500;
        }
        .imgSrc
        {
            margin-top:$grid-gutter-width/3;
            width:100%;
            border-radius: $border-radius;
        }
        .texts
        {
            .text
            {
                margin-top:$grid-gutter-width/3;
                font-size: 14px;
            }
            &--title
            {
                font-weight: 500;
            }
            &--text
            {

            }
        }
        .disclaimer
        {
            font-size: 12px;
            margin-top:$grid-gutter-width/4;
            background:$white;
            padding:$grid-gutter-width/3;
            border-radius: $border-radius;
            display:flex;
            &--text
            {
                flex:1;
                padding-left:10px;
            }
            &--icon
            {
                svg
                {
                    height:auto;
                    width:20px;
                }
            }
        }
        .credits
        {
            font-size: 14px;
            margin-top:$grid-gutter-width/4;
            text-align: right;
            font-style:italic;
            margin-bottom:0;
        }

        //[style*="--aspect-ratio"]
        .imgSrc
        {
            background-size: cover;
            background-position: center center;
            width:100%;
            height:200px;
            position: relative;
            //@supports (--custom:property)
            //{
                height:auto;
                &:before
                {
                    content: "";
                    display: block;
                    //padding-bottom: calc(100% / var(--aspect-ratio));
                    padding-bottom: calc(100% / 16 * 9);
                }  
            //}
        }

        .back *
        {
            color:$black;
            svg
            {
                path
                {
                    stroke:$black;
                }
            }
        }
    }

    /*body.customized .inSalabamInfoBox .abstract:after
    {
        background: linear-gradient(180deg, transparent, var(--custom-secondary) 100%);
    }*/
</style>