<template>
    <div class="results-head">
        <span class="headContent" v-if="total" v-b-popover.hover.top="'Ti stiamo mostrando solo una parte delle strutture disponibili. Clicca in fondo alla lista per caricarne altre'">
            <!--<SvgInfoCircle/>-->

            <p class="common-title" v-if="total < possibleProperties">
                <span v-if="total==1">Migliore struttura da scoprire su </span>
                <span v-else>Ecco le prime <strong>{{total}} strutture</strong> da scoprire su </span>
                {{possibleProperties}} totali
            </p>

            <!-- gestione copy nearby -->
            <p class="common-title" v-else>
                Scopri <strong>{{total}}</strong>&nbsp;<span v-if="total==1">struttura&nbsp;</span> <span v-else>strutture</span>

                <span v-if="!searchedOnCustomList">
                  a <strong>{{geoLabel}}</strong> e dintorni
                </span>
                <span v-else>
                  de <strong>{{geoLabel}}</strong>
                </span>

            </p>
        </span>

        <div class="headMore" v-if="offset!==-1">
          <!--<b-button v-if="!appending && !loading" v-on:click="this.$parent.$parent.$parent.getResults" variant="cta">Aggiungine altre</b-button>-->
          <a href="" onclick="return false" class="btn btn-sm mt-2" v-if="!appending && !loading" v-on:click="this.$parent.$parent.$parent.getResults">
            Scoprine altre...
          </a>
          <Spinner v-else />
        </div>

        <!--
        <span class="headContent" v-else>
            <span>{{total}}</span>
            <span>
                <span v-if="total==1">&nbsp;struttura trovata</span><span v-else>&nbsp;strutture trovate</span>
                <span class="map-link">
                    <router-link :to="{ 
                        name: 'map-list',
                        params: { slug: (geoLabel).replace(/ /g, '-'), title: geoLabel, propertyId: 0}
                    }">
                        vedi sulla mappa <SvgPin />
                    </router-link>
                </span>
            </span>
        </span>
        -->                
    </div>
</template>


<script>
import Spinner from '../atoms/spinner'

export default {
    name: 'results-head',
    components:
    {
        Spinner
    },
    props: {
        total: Number,
        geoLabel: String,
        possibleProperties: Number
    },
    computed:
    {
        loading: {
            get() { return this.$parent.$parent.$parent.loading },
            set() { }
        },
        appending: {
            get() { return this.$parent.$parent.$parent.appending },
            set() { }
        },
        offset: {
            get() { return this.$parent.$parent.$parent.offset },
            set() { }
        },
        searchedOnCustomList: {
          get(){

            let geo = this.$parent.$parent.$parent.geo;
            let matchLevelsNoNeedsNearBySearch = ['inSalabam', 'property', 'properties', 'favorites', 'history'];

            return !(matchLevelsNoNeedsNearBySearch.indexOf(geo.matchLevel) === -1)
          },
          set(){}
        }
    }
}
</script>

<style lang="scss">
    
    
    
    .results-head
    {
        display:block;
        width:100%;
        .headContent,
        .headMore
        {
            width:100%;
            display:flex;
            justify-content:center;
        }
        .headMore
        {
            justify-content:flex-end;
            //margin-top:$grid-gutter-width/10;
            .btn
            {
                //width:100%;
            }

        }
        svg
        {
            width:25px;
        }
        .common-title
        {
            font-weight: normal;
            font-size: 18px;
        }
    }
</style>